







import { Component, Vue } from "nuxt-property-decorator";
import GlobalHeader from "~/components/GlobalHeader.vue";
import "focus-visible";
@Component({
  components: { GlobalHeader }
})
export default class extends Vue {}
