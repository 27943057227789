import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faSearch as fortawesomefreesolidsvgicons_faSearch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSearch)

    import  { faBars as fortawesomefreesolidsvgicons_faBars } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faBars)

    import  { faCalendarPlus as fortawesomefreesolidsvgicons_faCalendarPlus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCalendarPlus)

    import  { faUser as fortawesomefreesolidsvgicons_faUser } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faUser)

    import  { faCog as fortawesomefreesolidsvgicons_faCog } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCog)

    import  { faSignOutAlt as fortawesomefreesolidsvgicons_faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faSignOutAlt)

    import  { faCalendarMinus as fortawesomefreesolidsvgicons_faCalendarMinus } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCalendarMinus)

    import  { faQuestionCircle as fortawesomefreesolidsvgicons_faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faQuestionCircle)

    import  { faEdit as fortawesomefreesolidsvgicons_faEdit } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEdit)

    import  { faTimes as fortawesomefreesolidsvgicons_faTimes } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTimes)

    import  { faComment as fortawesomefreesolidsvgicons_faComment } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faComment)

    import  { faLink as fortawesomefreesolidsvgicons_faLink } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faLink)

    import  { faCircleNotch as fortawesomefreesolidsvgicons_faCircleNotch } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faCircleNotch)

    import  { faExternalLinkAlt as fortawesomefreesolidsvgicons_faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faExternalLinkAlt)

    import  { faGoogle as fortawesomefreebrandssvgicons_faGoogle } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faGoogle)

    import  { faGithub as fortawesomefreebrandssvgicons_faGithub } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faGithub)

    import  { faTwitter as fortawesomefreebrandssvgicons_faTwitter } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faTwitter)

    import  { faFacebook as fortawesomefreebrandssvgicons_faFacebook } from '@fortawesome/free-brands-svg-icons'
    library.add(fortawesomefreebrandssvgicons_faFacebook)

    import  { faCalendar as fortawesomefreeregularsvgicons_faCalendar } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCalendar)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
