


















































































import { Component, Vue } from "nuxt-property-decorator";
import { loginWithFirebase, logoutWithFirebase } from "~/lib/Auth";
import UserIcon from "~/components/UserIcon.vue";

@Component({
  components: { UserIcon }
})
export default class extends Vue {
  isShownDropdown = false;

  mounted() {
    document.addEventListener("click", this.handleClickDocument);
    document.addEventListener("keyup", this.handleKeyupDocument);
  }

  destroyed() {
    document.removeEventListener("click", this.handleClickDocument);
    document.removeEventListener("keyup", this.handleKeyupDocument);
  }

  handleClickDocument() {
    this.hideDropdown();
  }

  handleKeyupDocument(e) {
    if (e.key === "Escape") {
      this.hideDropdown();
    }
  }

  showDropdown() {
    this.isShownDropdown = true;
  }

  hideDropdown() {
    this.isShownDropdown = false;
  }

  loginWithFacebook() {
    this.$router.push("/facebook-login");
  }

  login(provider) {
    loginWithFirebase(provider);
  }

  async logout() {
    this.$router.push("/");
    this.isShownDropdown = false;
    await logoutWithFirebase();
    window.alert("ログアウトしました。");
  }
}
