import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _e02e8f3a = () => interopDefault(import('../pages/archive.vue' /* webpackChunkName: "pages/archive" */))
const _63eea2d9 = () => interopDefault(import('../pages/calendars/index.vue' /* webpackChunkName: "pages/calendars/index" */))
const _18c8fdde = () => interopDefault(import('../pages/facebook-login.vue' /* webpackChunkName: "pages/facebook-login" */))
const _2ec287f0 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _cb87627e = () => interopDefault(import('../pages/new.vue' /* webpackChunkName: "pages/new" */))
const _aa93afae = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _49d16ef1 = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _d0347dc6 = () => interopDefault(import('../pages/supporter.vue' /* webpackChunkName: "pages/supporter" */))
const _2a98a944 = () => interopDefault(import('../pages/calendars/_id/index.vue' /* webpackChunkName: "pages/calendars/_id/index" */))
const _8ed41bd8 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _18074188 = () => interopDefault(import('../pages/calendars/_id/edit.vue' /* webpackChunkName: "pages/calendars/_id/edit" */))
const _06bf73cb = () => interopDefault(import('../pages/calendars/_id/embed.vue' /* webpackChunkName: "pages/calendars/_id/embed" */))
const _2eb866b3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/archive",
      component: _e02e8f3a,
      name: "archive"
    }, {
      path: "/calendars",
      component: _63eea2d9,
      name: "calendars"
    }, {
      path: "/facebook-login",
      component: _18c8fdde,
      name: "facebook-login"
    }, {
      path: "/help",
      component: _2ec287f0,
      name: "help"
    }, {
      path: "/new",
      component: _cb87627e,
      name: "new"
    }, {
      path: "/privacy",
      component: _aa93afae,
      name: "privacy"
    }, {
      path: "/setting",
      component: _49d16ef1,
      name: "setting"
    }, {
      path: "/supporter",
      component: _d0347dc6,
      name: "supporter"
    }, {
      path: "/calendars/:id",
      component: _2a98a944,
      name: "calendars-id"
    }, {
      path: "/users/:id?",
      component: _8ed41bd8,
      name: "users-id"
    }, {
      path: "/calendars/:id/edit",
      component: _18074188,
      name: "calendars-id-edit"
    }, {
      path: "/calendars/:id/embed",
      component: _06bf73cb,
      name: "calendars-id-embed"
    }, {
      path: "/",
      component: _2eb866b3,
      name: "index"
    }],

    fallback: false
  })
}
