












import { Component, Vue, Prop } from "nuxt-property-decorator";
import PageHeader from "~/components/PageHeader.vue";
@Component({
  components: { PageHeader }
})
export default class extends Vue {
  @Prop() readonly error;

  mounted() {
    console.error(this.error);
  }

  get notFound(): boolean {
    return this.error.statusCode === 404;
  }
}
